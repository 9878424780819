import styled from "styled-components";

const LeadrocksInfoHeaderWrapper = styled.section`
  display: flex;
  margin: 80px auto 50px;
  align-items: center;
  justify-content: space-evenly;
  background: url(${(props) => (props.avatar ? props.avatar : null)});
  background-position: center center;
  background-repeat: no-repeat;
  color: white;
  padding: 20px;

  .text {
    width: 50%;
    margin-right: 20px;
    text-align: center;
    .blue {
      font-style: italic;
      color: #04a7dc;
    }

    h1 {
      font-size: 48px;
      margin-top: 0;
      margin-bottom: 0;
    }
    h2 {
      font-size: 32px;
    }
    h3 {
      font-size: 32px;
    }
    h4 {
      font-size: 24px;
    }
    @media screen and (max-width: 768px) {
      width: 90%;
    }
  }
  @media screen and (max-width: 768px) {
    flex-direction: column;
    > div {
      width: 60%;
    }
  }
`;

export default LeadrocksInfoHeaderWrapper;
